import smoothscroll from 'smoothscroll-polyfill';
import { $, $$, $id, delegate, offset } from './helper/utils';
import outline from './helper/outline';
import inView from './helper/inView';
import navigation from './components/navigation';

smoothscroll.polyfill();

const sliderMap = new Map();
const sliderDragging = new Map();

outline();
navigation();

for (const scrollLink of $$('a[href^="#"]:not(.tab-link):not([data-toggle])')) {
  const href = scrollLink.getAttribute('href');

  if (href.length === 1 || scrollLink.parentElement.classList.contains('filter-item')) continue;

  const target = $(href) || $(href.replace('#', '.'));
  if (target !== null) {
    scrollLink.addEventListener('click', (e) => {
      e.preventDefault();
      console.log(target);
      window.scrollTo({ top: offset(target).top - $('#header').offsetHeight, behavior: 'smooth' });
    });
  }
}

if (window.location.hash.length > 1) {
  const target = $(window.location.hash);

  if (target !== null) {
    setTimeout(() => {
      window.scrollTo({ top: 0 });

      setTimeout(() => {
        window.scrollTo({ top: offset(target).top - $('#header').offsetHeight });
      }, 20);
    }, 10);
  }
}

export default function () {
  if ($('.siema') !== null) {
    import('./components/slider')
      .then((slider) => slider.default(sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  if ($('.custom-select') !== null) {
    import('./components/forms/select')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  }

  if ($('.form-group > .form-line') !== null) {
    import('./components/forms/form')
      .then((form) => form.default())
      .catch((err) => {
        console.warn('Chunk forms/form.js loading failed', err);
      });
  }

  if ($('.projects-grid') !== null) {
    import('./components/projects')
      .catch((err) => {
        console.warn('Chunk projects.js loading failed', err);
      });
  }

  if ($('.team-overview .filter') !== null) {
    import('./components/team')
      .catch((err) => {
        console.warn('Chunk team.js loading failed', err);
      });
  }

  inView($('#locationmap'), () => {
    import('./components/locationMap')
      .catch((err) => {
        console.warn('Chunk locationMap.js loading failed', err);
      });
  }, true);

  if ($('.history-years') !== null && document.body.hasAttribute('data-versstatus') === false) {
    import('./components/historyYears')
      .catch((err) => {
        console.warn('Chunk historyYears.js loading failed', err);
      });
  }

  if (document.body.classList.contains('project')) {
    import('./components/projectDetail')
      .catch((err) => {
        console.warn('Chunk projectDetail.js loading failed', err);
      });
  }

  // inView($$('.lightbox'), () => {
  //   import('./components/lightbox.js')
  //     .then((lightbox) => lightbox.default('.lightbox', sliderMap, sliderDragging))
  //     .catch((err) => {
  //       console.warn('Chunk lightbox.js loading failed', err);
  //     });
  // }, true);

  if ($('.filter') !== null) {
    import('./components/filter')
      .catch((err) => {
        console.warn('Chunk modal.js loading failed', err);
      });
  }

  if ($$('.nav-tabs, [data-toggle="collapse"]').length) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      gsap.fromTo(href, { duration: 0.3, opacity: 0 }, { opacity: 1, ease: 'linear' });

      const tabbq = e.relatedTarget.parentElement.querySelector('.tabbg');
      gsap.to(tabbq, {
        duration: 0.325,
        x: e.relatedTarget.offsetLeft,
        scaleX: e.relatedTarget.offsetWidth / tabbq.offsetWidth,
        ease: 'sine.inOut',
      });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }

        for (const tab of $$('.nav-tabs')) {
          const tabbg = document.createElement('div');
          tabbg.className = 'tabbg';
          tab.prepend(tabbg);
          gsap.set(tabbg, {
            scaleX: tab.querySelector('.active').offsetWidth / tabbg.offsetWidth,
          });
        }

        for (const el of $$('.nav-tabs .tab-link')) {
          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }
}
