/**
 * Execute a function once an element is entering the viewport
 *
 * @param {Element|NodeList} elements The elements to observe
 * @param {function} callback The callback function to execute
 * @param {boolean=false} removeAll Remove observers from all remaing elements
 * @return {void}
 */
export default (elements, callback, customOptions = {}, removeAll = false) => {
  if (elements === null || elements.length === 0) {
    console.info('No elements found');
    return;
  }

  if (callback == null || typeof callback !== 'function') {
    console.warn('No callback function provided');
    return;
  }

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
    ...customOptions,
  };

  // observer function that get's called when an element intersects the viewport
  const observer = new IntersectionObserver((entries) => {
    // get all visible entries
    const targets = [];

    for (let i = 0; i < entries.length; i++) {
      const el = entries[i];
      if (el.isIntersecting === true) {
        const { target } = el;
        targets.push(target);

        // remove observer from element
        observer.unobserve(target);

        if (removeAll === true) {
          for (let j = 0; j < entries.length; j++) {
            observer.unobserve(entries[j].target);
          }
        }
      }
    }

    for (let i = 0; i < targets.length; i++) {
      const el = targets[i];

      callback(el, i, i + 1 === targets.length);
    }
  }, options);

  if (elements.length) {
    // setup overserver for each element
    for (let i = 0; i < elements.length; i++) {
      const el = elements[i];
      observer.observe(el);
    }
  } else {
    const el = elements;
    observer.observe(el);
  }
};
